import React, { useState, useEffect } from 'react';
import { Container, Box, Tabs, Tab, Typography, Button } from '@mui/material';
import Header from '../components/Header';
import SnackbarAlert from '../components/SnackbarAlert';
import FileUpload from '../components/FileUpload';
import TabContent from '../components/TabContent';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';


function LinkedIn_Wizard() {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [grade, setGrade] = useState('--');
    const [status, setStatus] = useState('');
    const [fileName, setFileName] = useState(''); // State for file name
    const [comments, setComments] = useState({
        work: [],
        education: [],
        details: [],
    });
    const [additionalRecommendations, setAdditionalRecommendations] = useState(`
	Profile Photo: Avoid using casual or unclear photos. Ensure your headshot is professional, friendly, and clear.
	Banner Image: Avoid leaving the banner blank or using irrelevant images. Include a banner image relevant to AI, data, or technology themes.
	Recommendations: Avoid neglecting recommendations. Request them from mentors, peers, or managers who can vouch for your technical and professional skills.
	Activity & Engagement: Avoid being inactive. Regularly engage with AI/data content by sharing articles, projects, or career milestones.
    `);

    const [tabIndex, setTabIndex] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (grade.startsWith('00')) {
            setFile(null);
            setProcessing(false);
            setStatus('');
            setFileName(''); // Clear file name on error
            setComments({
                work: [],
                education: [],
                details: [],
            });
        }
    }, [grade]);

    const handleError = (message) => {
        setError(message);
        setShowError(true);
    };

    const onDrop = async (acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        if (!uploadedFile) {
            handleError('File format not supported. Please upload a PDF or Word file.');
            return;
        }

        if (uploadedFile.size > 2097152) {
            handleError('File size exceeds 2MB limit.');
            return;
        }
        await sendFileToBackend(uploadedFile);
    };

    const sendFileToBackend = async (uploadedFile) => {
        setProcessing(true);
        setGrade('--');
        setStatus('');
        setFileName(''); // Clear file name before processing
        setComments({
            work: [],
            education: [],
            details: [],
        });

        const formData = new FormData();
        formData.append('file', uploadedFile);

        try {
            const response = await axios.post('api/upload_LI', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            const { comments: receivedComments, name } = response.data;
            setGrade(receivedComments.grade || '--');
            setStatus(receivedComments.status || '');
            setFileName(receivedComments.name|| ''); // Set file name
            setComments({
                work: receivedComments.work || ['No comments on work experience.'],
                education: receivedComments.education || ['No comments on education.'],
                details: receivedComments.details || ['No general comments available.'],
            });
        } catch (error) {
            handleError('There was an error analyzing the file. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleCloseError = () => {
        setShowError(false);
    };

    const getMessageLine = () => {
        if (grade.startsWith('00')) {
            return (
                <Typography
                    style={{
                        color: 'red',
                        fontFamily: 'Space Mono',
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    The provided file doesn't appear to be a LinkedIn report. Please upload a valid file for evaluation.
                </Typography>
            );
        }
        if (parseInt(grade, 10) < 70 && grade !== '--') {
            return (
                <Typography
                    style={{
                        color: 'white',
                        fontFamily: 'Space Mono',
                        textAlign: 'center',
                    }}
                >
                    Don't get discouraged! Follow our detailed comments and you'll have the perfect LinkedIn analysis!
                </Typography>
            );
        }
        return null;
    };

    return (
        <Container>
            <Header title="LinkedIn Wizard" />
            {/* Back Button */}
        <Box mt={-4} mb={2}>
            <Button
                variant="contained"
                onClick={() => navigate('/')} // Navigate back to HomePage
                style={{
                    fontFamily: 'Space Mono',
                    color: 'black', // Black text
                    fontSize: '16px',
                    textTransform: 'none',
                    backgroundColor: 'rgb(162, 194, 78)', // Matching green background
                    padding: '4px 16px', // Add padding for better button size
                    borderRadius: '8px', // Rounded corners
                }}
            >
                ← Back
            </Button>
        </Box>
            <SnackbarAlert error={error} showError={showError} handleCloseError={handleCloseError} />
            <Typography
                    variant="h7"
                    style={{
                        fontFamily: 'Space Mono',
                        color: 'white',
                        marginBottom: '10px',
                        maxWidth: '1200px', // Limit width for readability
                    }}
                >
                    Go to LinkedIn, click on "More" right below the profile photo then save to PDF. Upload it below for evaluation.
                </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '600px' }}>
                    <FileUpload onDrop={onDrop} processing={processing} />
                </Box>

                <Box
                    style={{
                        flex: 1,
                        padding: 16,
                        backgroundColor:
                            status.toLowerCase() === 'passed'
                                ? 'rgb(162, 194, 78)'
                                : status.toLowerCase() === 'failed'
                                ? 'rgb(255, 99, 71)'
                                : 'rgb(211, 211, 211)',
                        height: 50, // Matches upload button height
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                    }}
                >
                    <Typography variant="h4" style={{ color: 'black', fontFamily: 'Space Mono' }}>
                        Grade:
                    </Typography>
                    <Typography variant="h5" style={{ color: 'black', fontFamily: 'Space Mono' }}>
                        {grade} {status && `| ${status.toUpperCase()}`}
                    </Typography>
                </Box>
            </Box>

            {fileName && (
                <Box mt={2} textAlign="center">
                    <Typography
                        style={{
                            color: 'white',
                            fontFamily: 'Space Mono',
                            textAlign: 'center',
                        }}
                    >
                        File Name: {fileName}
                    </Typography>
                </Box>
            )}

            {getMessageLine() && (
                <Box mt={2} mb={2}>
                    {getMessageLine()}
                </Box>
            )}

<Tabs 
    value={tabIndex} 
    onChange={handleTabChange} 
    indicatorColor="primary" 
    textColor="primary" 
    style={{ marginBottom: 16 }}
>
    <Tab label="Profile" style={{ color: 'white', fontFamily: 'Space Mono' }} />
    <Tab label="Work Experience" style={{ color: 'white', fontFamily: 'Space Mono' }} />
    <Tab label="Education" style={{ color: 'white', fontFamily: 'Space Mono' }} />
    <Tab label="Common Mistakes to Avoid" style={{ color: 'white', fontFamily: 'Space Mono' }} />
    <Tab label="AI Certification Experience" style={{ color: 'white', fontFamily: 'Space Mono' }} /> {/* Added tab */}
</Tabs>

            {tabIndex !== 3 && <TabContent tabIndex={tabIndex} comments={comments} />}

            {tabIndex === 3 && (
                <Box style={{ color: 'white', fontFamily: 'Space Mono' }}>
                    <Typography
                        variant="h6"
                        style={{
                            marginBottom: '8px',
                            fontWeight: 'bold',
                        }}
                    >
                        
                    </Typography>
                    <ul
                        style={{
                            listStyleType: 'disc',
                            paddingLeft: '20px',
                            lineHeight: '1.8',
                            color: 'white',
                        }}
                    >
                        {additionalRecommendations
                            .trim()
                            .split('\n')
                            .map((line, index) => {
                                const [title, ...rest] = line.split(':'); // Split into title and rest
                                return (
                                    <li key={index} style={{ marginBottom: '8px', color: 'white' }}>
                                        <span style={{ fontWeight: 'bold' }}>{title.trim()}:</span>{' '}
                                        <span>{rest.join(':').trim()}</span> {/* Join rest in case there's more than one colon */}
                                </li>
                           );
                        })}
                    </ul>
                </Box>
            )}
{tabIndex === 4 && (
    <Box>
        <Typography
            variant="body1"
            style={{
                marginBottom: '20px',
                color: 'white',
                fontFamily: 'Space Mono',
            }}
        >
            Follow these templates to correctly showcase your ZAKA certification experience on LinkedIn:
        </Typography>
        <Box
            style={{
                marginBottom: '40px',
                color: 'white',
                fontFamily: 'Space Mono',
                textAlign: 'justify',
                lineHeight: '1.8',
                maxWidth: '1200px', // Restrict the width of the text
            }}
        >
            {/* Machine Learning Specialization */}
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    marginBottom: '12px',
                    color: 'rgb(162, 194, 78)',
                }}
            >
                Machine Learning Specialization
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                Completed the 20-week Machine Learning Specialization training program designed to prepare for roles in Artificial Intelligence and Machine Learning. The program covered topics such as Data Science fundamentals, Machine Learning algorithms, Deep Learning, Computer Vision, Natural Language Processing, and deploying AI solutions in production and research environments.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                During the program, I completed a capstone project on &lt;&lt;Add project title&gt;&gt; where &lt;&lt;Add project description with specifying main technologies and tools used...&gt;&gt;.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                ZAKA is an Artificial Intelligence company offering educational training programs to equip individuals with the skills and expertise required for today’s AI-driven world.
            </Typography>

            {/* Data Science Specialization */}
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    marginBottom: '12px',
                    color: 'rgb(162, 194, 78)',
                }}
            >
                Data Science Specialization
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                Completed the 20-week Data Science Specialization training program designed to prepare for roles in Artificial Intelligence and Data Science. The program covered topics such as Data Cleaning and Preparation, Exploratory Data Analysis, Statistical Analysis, Machine Learning Algorithms, and Data Visualization.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                During the program, I completed a capstone project on &lt;&lt;Add project title&gt;&gt; where &lt;&lt;Add project description with specifying main technologies and tools used...&gt;&gt;.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                ZAKA is an Artificial Intelligence company offering educational training programs to equip individuals with the skills and expertise required for today’s AI-driven world.
            </Typography>

            {/* Data Analytics Specialization */}
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    marginBottom: '12px',
                    color: 'rgb(162, 194, 78)',

                }}
            >
                Data Analytics Specialization
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                Completed the 20-week Data Analytics Specialization training program designed to prepare for roles in Artificial Intelligence and Data Analytics. The program covered topics such as Data Cleaning and Manipulation, Business Intelligence Tools, Dashboards Creation (e.g., Power BI, Tableau), Data Storytelling, and Statistical Analysis.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                During the program, I completed a capstone project on &lt;&lt;Add project title&gt;&gt; where &lt;&lt;Add project description with specifying main technologies and tools used...&gt;&gt;.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                ZAKA is an Artificial Intelligence company offering educational training programs to equip individuals with the skills and expertise required for today’s AI-driven world.
            </Typography>

            {/* Data Engineering Specialization */}
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    marginBottom: '12px',
                    color: 'rgb(162, 194, 78)',
                }}
            >
                Data Engineering Specialization
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                Completed the 20-week Data Engineering Specialization training program designed to prepare for roles in Artificial Intelligence and Data Engineering. The program covered topics such as Building and Optimizing ETL Pipelines, Big Data Management, Cloud Platforms (e.g., AWS, GCP), Database Architecture, and Scalable Data Solutions.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                During the program, I completed a capstone project on &lt;&lt;Add project title&gt;&gt; where &lt;&lt;Add project description with specifying main technologies and tools used...&gt;&gt;.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
                ZAKA is an Artificial Intelligence company offering educational training programs to equip individuals with the skills and expertise required for today’s AI-driven world.
            </Typography>
        </Box>
    </Box>
)}



        </Container>
    );
}

export default LinkedIn_Wizard;
